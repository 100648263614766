import React from 'react'

// Libreries
import { Col, Row } from "react-bootstrap";
//import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

// Components
import Button from "../../../../CommonComponents/Button/Button";

// Icons
import Linkedin from "../../../../Assets/icons/linkedin.svg"
import Youtube from "../../../../Assets/icons/youtube.svg"
import Instagram from "../../../../Assets/icons/instagram.svg"
import Lyopay from "../../../../Assets/icons/lyopay.svg"
import Jcmanagement from "../../../../Assets/icons/jcmanagement.svg"
import MichieleMunaretto from "../../../../Assets/icons/michieleMunaretto.svg"
import Paco from "../../../../Assets/icons/paco.svg"
import SecurityAgency from "../../../../Assets/icons/securityAgency.svg"
import Light1 from "../../../../Assets/icons/lights.svg"
import Light2 from "../../../../Assets/icons/lights2.svg"

// Styles
import 'swiper/css';
import Style from './Hero.module.css'

// Data
const logos = [
    {
        id: 1,
        logo: Lyopay
    },
    {
        id: 2,
        logo: Jcmanagement
    },
    {
        id: 3,
        logo: MichieleMunaretto
    },
    {
        id: 4,
        logo: Paco
    },
    {
        id: 5,
        logo: SecurityAgency
    },
]

const Hero = () => {
    return (
        <>
            <Row className={Style.spacing}>
                <Col md={12} lg={5} className={Style.center}>
                    <div className={Style.header}>
                        <span className={Style.label}>Listen, Learn, Guide</span>
                        <h1 className={Style.title}>
                            Realise your goals <span>And live</span> as you wish
                        </h1>
                        <p className={Style.content}>If you are in focus and want to realise your goals, both personal and professional, you are on the right track. Together we can do the rest: work on your self-awareness and prepare you for the challenges of the future.</p>
                        <Button text="About me" type={3} path='about'/>
                    </div>
                </Col>
                <Col md={12} lg={7}>
                    <div className={Style.rightBox}>
                        <div className={Style.box}>
                            <div className={Style.card}>
                                <img className={Style.cardOneLight} src={Light1} alt="" />
                            </div>
                            <div className={Style.secondCard}>
                                <div className={Style.frame}></div>
                                <div className={Style.card2}>
                                    <img className={Style.cardTwoLight} src={Light2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={Style.social}>
                            <div className={Style.line}></div>
                            <ul className={Style.list}>
                                <li>
                                    <a href="https://www.linkedin.com/in/elia-de-prisco-15572911/" target="_blank" rel="noreferrer">
                                        <img src={Linkedin} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@EliaDePriscoOfficial" target="_blank" rel="noreferrer">
                                        <img src={Youtube} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/eliadepriscofficial/" target="_blank" rel="noreferrer">
                                        <img src={Instagram} alt="" />
                                    </a>
                                </li>
                            </ul>
                            <div className={Style.line}></div>
                        </div>
                    </div>

                </Col>
            </Row>
            <div className={Style.partnerSlide}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={5}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    // when window width is >=
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        610: {
                            slidesPerView: 2,
                        },
                        878: {
                            slidesPerView: 3,
                        },
                        1000: {
                            slidesPerView: 4,
                        },
                        1199: {
                            slidesPerView: 5,
                        }
                    }}
                    modules={[Autoplay]}
                >

                    {logos.map((el) => {
                        return (
                            <SwiperSlide key={el.id} style={{ height: 120 }}>
                                <div className={Style.logoPartner}>
                                    <img src={el.logo} alt="partner logo" />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}

export default Hero