import React from "react";

//Styles
import Styles from "./Page404.module.css";

// Components
import Button from "../../CommonComponents/Button/Button";

const Page404 = () => {
  return (
    <div className={Styles.forofordiv}>
      <h1  className={Styles.forofortitle}>Page not Found 404</h1>
      <Button text="Return to the Home Page" type={3} path='/'/>
    </div>)
};

export default Page404;
