import React from "react";

// Components
import AboutMe from "../../CommonComponents/AboutMe/AboutMe";
import Hero from "./Components/Hero/Hero";
import MyBusiness from "./Components/MyBusiness/MyBusiness";
import Review from "./Components/Review/Review";
import WorkTogether from "./Components/WorkTogether/WorkTogether";

// Styles
import Style from "./HomePage.module.css";

const HomePage = () => {
  return (
    <>
      <section className={Style.hero}>
        <Hero />
      </section>
      <section className={Style.myBusiness}>
        <MyBusiness />
      </section>
      <section className={Style.aboutMe}>
        <AboutMe />
      </section>
      <section>
        <WorkTogether />
      </section>
      <section className={Style.review}>
        <Review />
      </section>
    </>
  );
};

export default HomePage;
