import React from "react";

// Styles
import Style from "./Stats.module.css";

const cards = [
  {
    title: "32000",
    description: "Satisfied customers",
  },
  {
    title: "15",
    description: "Projects Tackled",
  },
  {
    title: "500+",
    description: "Speach Held",
  },
  {
    title: "30+",
    description: "Years of Experience",
  },
];

const Stats = () => {
  return (
    <div className={Style.statsContainer}>
      {cards.map((card, index) => (
        <div key={index} className={Style.stats}>
          <h2>{card.title}</h2>
          <p>{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Stats;
