import React from "react";

// Icons
import Building from "../../../../Assets/icons/building.svg";
import Coordinating from "../../../../Assets/icons/coordinating.svg";
import Strength from "../../../../Assets/icons/strength.svg";
import Effective from "../../../../Assets/icons/effective.svg";

// Styles
import Style from "./WhatCanTogether.module.css";

const cards = [
  {
    icon: Building,
    title: "Building team synergy",
    text: "Having a compact and united team is the first step to success with one's company: working on relationships therefore becomes crucial.",
  },
  {
    icon: Coordinating,
    title: "Coordinating the capacities of everyone",
    text: "In my work, I don't want to leave anyone behind: I focus on each person, banishing doubts and fears and leveraging skills and strengths.",
  },
  {
    icon: Strength,
    title: "Strengthening the union",
    text: "Someone once said that unity is strength, and it is only by working on the skills of each that the end result will be truly choral and successful.",
  },
  {
    icon: Effective,
    title: "Effective communication",
    text: "Yet, there is no result without proper communication: to do so, it is essential to know the rules and apply a positive mindset.",
  },
];

const WhatCanTogether = () => {
  return (
    <div className={Style.whatCanTogetherContainer}>
      <div className={Style.sectionTitle}>
        <div className={Style.yellowLine}></div>
        <h2>What can we do together?</h2>
        <div className={Style.yellowLine}></div>
      </div>
      <div className={Style.logoGrid}>
        {cards.map((card, index) => (
          <div key={index} className={Style.card}>
            <img src={card.icon} alt={card.title} />
            <h3>{card.title}</h3>
            <p>{card.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatCanTogether;
