import React from 'react'

// Libreries
import { Row, Col } from 'react-bootstrap'
//import { Link } from 'react-router-dom'

// Icons
import Linkedin from "../../../../Assets/icons/linkedinWhite.svg"
import Youtube from "../../../../Assets/icons/youtubeWhite.svg"
import Instagram from "../../../../Assets/icons/instagramWhite.svg"

// Styles 
import Style from './Hero.module.css'

const Hero = () => {
    return (
        <Row style={{ height: "100%", position: "relative" }}>
            <Col xl={12}>
                <div className={Style.header}>
                    <div className={Style.content}>
                        <small className={Style.label}>My Services</small>
                        <h1>The transformation expert</h1>
                        <p>Thousands of people have discovered their true potential thanks to the work we have done together. Why? Because this is what the project is all about: helping people understand themselves and feel more confident and empowered. Specialising in corporate communication, in my work I always   try to merge people's skills into a team synergy, uniting them through effective communication and a totally confident mindset.</p>
                    </div>
                </div>

                <div className={Style.social}>
                    <div className={Style.line}></div>
                    <ul className={Style.list}>
                        <li>
                            <a href="https://www.linkedin.com/in/elia-de-prisco-15572911/" target="_blank" rel="noreferrer">
                                <img src={Linkedin} alt="linkedin" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@EliaDePriscoOfficial" target="_blank" rel="noreferrer">
                                <img src={Youtube} alt="youtube" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/eliadepriscofficial/" target="_blank" rel="noreferrer">
                                <img src={Instagram} alt="instagram" />
                            </a>
                        </li>
                    </ul>
                    <div className={Style.line}></div>
                </div>
            </Col>
        </Row>
    )
}

export default Hero