import React from 'react';

// Libraries
import ReactDOM from 'react-dom/client';
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";

// App
import App from './App';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


const apiClient = generateApiClient({
  baseUrl: "https://apieliadeprisco.testing-room.com",
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApiProvider apiClient={apiClient}>
      <App />
    </ApiProvider>
  </React.StrictMode>
);

