import React from "react";

// Libraries
import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

// Icons
import ApexOpened from "../../../../Assets/icons/apexOpenedRed.svg";
import LogoGray from "../../../../Assets/logos/logoGray.svg";

// Styles
import "swiper/css/pagination";
import Style from "./Review.module.css";

const data = [
  {
    id: 1,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
  {
    id: 2,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
  {
    id: 3,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
  {
    id: 4,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
  {
    id: 5,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
  {
    id: 6,
    avatar: ApexOpened,
    text: "I felt heard for the first time in my life. Thanks to Elia and private sessions with him, I overcame the difficulties that made my work a burden.",
  },
];

const Review = () => {
  return (
    <Row>
      <Col sm={12} xl={8}>
        <div className={Style.leftBox}>
          <div className={Style.header}>
            <div className={Style.title}>
              <h2>What they say about me</h2>
              <img src={ApexOpened} alt="apex" />
            </div>
            <p>
              Today I can call myself a trainer because, in over 30 years of
              experience, I have trained myself. In all this time, my meetings
              and tips have left their mark on the people I have worked with.
              And this is what they say about me.
            </p>
          </div>

          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
            }}
            modules={[Autoplay, Pagination]}
          >
            <div className="swiper-pagination"></div>

            {data.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className={Style.box}>
                    <div className={Style.avatar}>
                      <div
                        className={Style.background}
                        style={{ backgroundImage: `url(${el.avatar})` }}
                      ></div>
                    </div>
                    <p>
                      {'"'}
                      {el.text}
                      {'"'}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Col>
      <Col sm={0} xl={4}>
        <div className={Style.rightBox}>
          <div className={Style.circle}>
            <img className={Style.centerImage} src={LogoGray} alt="" />
            <div className={Style.miniCircle1}></div>
            <div className={Style.miniCircle2}></div>
            <div className={Style.miniCircle3}></div>

            {data.map((el) => {
              return (
                <div key={el.id} className={Style["image" + el.id]}>
                  <div
                    className={Style.background}
                    style={{ backgroundImage: `url(${el.avatar})` }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Review;
