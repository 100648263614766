import React from "react";

// Components
import Button from "../Button/Button";

// Libraries
import { Row, Col } from "react-bootstrap";

// Styles
import Style from "./CustomRow.module.css";

const CustomRow = ({
  image,
  textButtonOne,
  textButtonTwo,
  pathButtonOne,
  pathButtonTwo,
  orderColumnOne = 1,
  orderColumnTwo = 2,
  title,
  description,
}) => {
  return (
    <Row className={Style.customRow}>
      <Col lg={{ span: 6, order: orderColumnOne }}>
        <div
          className={Style.image}
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
      </Col>
      <Col lg={{ span: 6, order: orderColumnTwo }}>
        <div className={Style.textContent}>
          <h3>{title}</h3>
          <p>{description}</p>
          <div className={Style.buttons}>
            <Button path={pathButtonOne} text={textButtonOne} type={2} />
            <Button path={pathButtonTwo} text={textButtonTwo} type={2} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CustomRow;
