import React from "react";

// Libraries
import { Link } from "react-router-dom";

// Utils
import classNames from "../../Utils/classNames";

// Styles
import Style from "./Button.module.css";

const Button = ({
  text = "Insert text",
  path = "",
  type = 1,
  className,
  style,
  onClickButton = false,
  onClick = () => {},
}) => {
  return (
    <>
      {onClickButton ? (
        <div
          style={style}
          className={classNames(
            type === 1
              ? Style.buttonTypeOne
              : type === 2
              ? Style.buttonTypeTwo
              : Style.buttonTypeThree,
            className
          )}
          onClick={onClick}
        >
          {text}
        </div>
      ) : (
        <Link to={path}>
          <div
            style={style}
            className={classNames(
              type === 1
                ? Style.buttonTypeOne
                : type === 2
                ? Style.buttonTypeTwo
                : Style.buttonTypeThree,
              className
            )}
          >
            {text}
          </div>
        </Link>
      )}
    </>
  );
};

export default Button;
