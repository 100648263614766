import React, { useState } from "react";

// Compoenents
import Button from "../../../../CommonComponents/Button/Button";

// Libraries
import { Row, Col } from "react-bootstrap";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Icons
import Home from "../../../../Assets/icons/home.svg";
import Mail from "../../../../Assets/icons/mail.svg";
//import Phone from "../../../../Assets/icons/phone.svg";

// Styles
import Style from "./ContactForm.module.css";

const informations = [
  {
    icon: Home,
    title: "Head Quarter",
    description: "Based in Italy",
  },
  {
    icon: Mail,
    title: "Email",
    description: "consulting@eliadeprisco.com",
  },
  /*{
    icon: Phone,
    title: "Phone",
    description: "+123456789",
  },*/
];

const ContactForm = () => {
  const form = useForm({
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    phone: {
      required: true,
    },
    email: {
      required: true,
      type: "email",
    },
    company: {
      required: false,
    },
    message: {
      required: true,
    },
  });

  const { isLoading, executeQuery } = useQuery({
    url: "https://elia-website-back.vercel.app/api/contact",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      console.log(response);
      setFormMessage(true);
    },
    onError: (error) => {
      console.log(error);
    },
    onUnauthorized: (response) => {
      console.log(response);
    },
  });

  const [formMessage, setFormMessage] = useState(false);

  return (
    <Row>
      <Col lg={5}>
        <div className={Style.getInTouch}>
          <h3>Get in touch</h3>
          <div className={Style.yellowLine}></div>
        </div>
        <div className={Style.contentContainer}>
          {informations.map((info, index) => (
            <div key={index} className={Style.content}>
              <img src={info.icon} alt={info.title} />
              <div className={Style.information}>
                <h5>{info.title}</h5>
                <h3>{info.description}</h3>
              </div>
            </div>
          ))}
        </div>
      </Col>
      <Col lg={7}>
        <div className={Style.contactFormContainer}>
          {formMessage && (
            <div className={Style.formMessage}>
              <h2>Message sent correctly!</h2>
            </div>
          )}

          <div className={Style.header}>
            <h3>
              <span>Have a project? </span>Contact me to collaborate
            </h3>
          </div>
          <div className={Style.body}>
            <Row style={{ padding: 0 }}>
              <Col lg={6}>
                <InputField
                  placeholder="First Name"
                  value={form.values["firstName"]}
                  setValue={form.getInputProps("firstName").setValue}
                />
              </Col>
              <Col lg={6}>
                <InputField
                  placeholder="Last Name"
                  value={form.values["lastName"]}
                  setValue={form.getInputProps("lastName").setValue}
                />
              </Col>
              <Col lg={6}>
                <InputField
                  placeholder="Phone"
                  value={form.values["phone"]}
                  setValue={form.getInputProps("phone").setValue}
                />
              </Col>
              <Col lg={6}>
                <InputField
                  placeholder="Email"
                  value={form.values["email"]}
                  setValue={form.getInputProps("email").setValue}
                  error={form.errors["email"].value}
                  style={{
                    borderColor:
                      form.errors["email"].value === false && "#cf0303",
                  }}
                />
              </Col>
              <Col lg={12}>
                <InputField
                  placeholder="Company or organization"
                  value={form.values["company"]}
                  setValue={form.getInputProps("company").setValue}
                />
              </Col>
              <Col lg={12}>
                <InputArea
                  placeholder="Message or description"
                  value={form.values["message"]}
                  setValue={form.getInputProps("message").setValue}
                />
              </Col>
              <div className={Style.buttonContainer}>
                <Button
                  type={3}
                  text={isLoading ? "Sending..." : "Send"}
                  onClickButton={true}
                  onClick={() => {
                    if (form.isFormValid() && !isLoading) {
                      executeQuery(form.values);
                    }
                  }}
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    opacity: form.isFormValid() ? 1 : 0.5,
                    cursor: form.isFormValid() ? "pointer" : "not-allowed",
                  }}
                />
              </div>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ContactForm;

const InputField = ({ value, setValue, placeholder, style, error = null }) => {
  return (
    <div className={Style.inputField}>
      <input
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {error === false && (
        <p className={Style.error}>Please enter a valid email</p>
      )}
    </div>
  );
};

const InputArea = ({ value, setValue, placeholder, style }) => {
  return (
    <div className={Style.inputField}>
      <textarea
        placeholder={placeholder}
        rows="8"
        style={style}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};
