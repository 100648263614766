import React from 'react'

// Components
import Button from '../../../../CommonComponents/Button/Button'

// Libreries
import { Col, Row } from 'react-bootstrap'

// Icons
import Light1 from "../../../../Assets/icons/lights.svg"

// Styles 
import Style from './NoLimits.module.css'

const NoLimits = () => {
    return (
        <Row>
            <Col xl={{ span: 7 }} xs={{ order: 1 }}>
                <div className={Style.leftBox}>
                    <div>
                        <div className={Style.box1}>
                            <img src={Light1} alt="lights" />
                        </div>
                    </div>
                    <div className={Style.center}>
                        <div className={Style.box2}>
                            <img src={Light1} alt="lights" />
                        </div>
                        <div className={Style.box3}>
                            <img src={Light1} alt="lights" />
                        </div>

                    </div>
                </div>
            </Col>
            <Col xl={5} md={{ order: 1 }} className={Style.rightBox}>
                <div className={Style.header}>
                    <h3>No limits to change</h3>
                    <p>I have educated audiences from a variety of industries, appearing as a keynote speaker for an impressive number of clients, including Lighthouse, NSA and Mango Universe.</p>
                    <Button path={"/contact"} text='Contact Now' type={3} />
                </div>
            </Col>
        </Row>
    )
}

export default NoLimits