import React from "react";

// Components
import CustomRow from "../../../../CommonComponents/CustomRow/CustomRow";

// Images
import Image1 from "../../../../Assets/images/custom_row_s_1.webp";
import Image2 from "../../../../Assets/images/custom_row_s_2.webp";
import Image3 from "../../../../Assets/images/custom_row_s_3.webp";
import Image4 from "../../../../Assets/images/custom_row_s_4.webp";

// Styles
// import Style from "./CustomRows.module.css";

const customRows = [
  {
    image: Image1,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "My Philosophy",

    description:
      "Being authentic. It is not only a value I apply to my work, but it is also a precept that I convey to those who begin a journey with me.",
  },
  {
    image: Image2,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "My Mission",
    description:
      "I help people and companies to understand their true potential, channelling it towards personal and collective success.",
    orderColumnOne: 2,
    orderColumnTwo: 1,
  },
  {
    image: Image3,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "My Vision",
    description:
      "Knowing how to communicate, resolve conflicts, perceive power, achieving goals: there is nothing that cannot be tackle together.",
  },
  {
    image: Image4,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "My Method",
    description:
      "Talking, confrontation, dialogue: my method involves a continuous exchange with the people I work with, both live (at conferences, seminars and events) or full remote.",
    orderColumnOne: 2,
    orderColumnTwo: 1,
  },
];

const CustomRows = () => {
  return (
    <div>
      {customRows.map((item, index) => (
        <CustomRow
          key={index}
          image={item.image}
          textButtonOne={item.textButtonOne}
          textButtonTwo={item.textButtonTwo}
          pathButtonOne={item.pathButtonOne}
          pathButtonTwo={item.pathButtonTwo}
          title={item.title}
          description={item.description}
          orderColumnOne={item.orderColumnOne ? item.orderColumnOne : 1}
          orderColumnTwo={item.orderColumnTwo ? item.orderColumnTwo : 2}
        />
      ))}
    </div>
  );
};

export default CustomRows;
