import React from "react";

// Components
import Button from "../Button/Button";

// Styles
import Style from "./CallToAction.module.css";

const CallToAction = () => {
  return (
    <div className={Style.callToAction}>
      <h2>
        Do you want to start a path
        <span className={Style.styledH2}> together?</span>
      </h2>
      <Button text="Contact now" path="/contact" />
    </div>
  );
};

export default CallToAction;
