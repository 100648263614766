// Layouts
import MainLayout from './Layouts/MainLayout/MainLayout';

//Utils
import { ScrollToTop } from './Utils/scrollToTop';

// Views
import About from './Views/About/About';
import Contact from './Views/Contact/Contact';
import HomePage from './Views/HomePage/HomePage';
import Service from './Views/Service/Service';
import Page404 from './Views/Page404/Page404';

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Styles


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Page404 />} />
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service" element={<Service />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
