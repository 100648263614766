import React from "react";

// Components
import CAllToAction from "../CallToAction/CallToAction";

// Libraries
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

// Images
import Logo from "../../Assets/logos/logo.png";

// Icons
import Linkedin from "../../Assets/icons/linkedin_black.svg";
import Youtube from "../../Assets/icons/youtube_black.svg";
import Instagram from "../../Assets/icons/instagram_black.svg";

// Styles
import Style from "./Footer.module.css";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <div>
      {
        (pathname === "/contact")
          ?
          <></>
          :
          <CAllToAction />
      }
      <div className={Style.footerContainer}>
        <footer className={Style.footer}>
          <Row>
            <Col lg={6} md={12}>
              <img className={Style.footerLogo} alt="logo" src={Logo} />
              <p>
                Elia De Prisco has worked for 30 years as a communications
                consultant, expert in group dynamics and behaviour modification.
                He has educated audiences in a variety of industries, appearing
                as a keynote speaker for an impressive number of clients,
                including Lighthouse, NSA and Mango Universe.
              </p>
            </Col>
            <Col lg={3} md={{ span: 6, offset: 0 }}>
              <div className={Style.centerColFooter}>
                <div>
                  <h4>Sitemap</h4>
                  <ul>
                    <li className={Style.footerMenuItem}>
                      <Link to="/">Homepage</Link>
                    </li>
                    <li className={Style.footerMenuItem}>
                      <Link to="/about">About me</Link>
                    </li>
                    <li className={Style.footerMenuItem}>
                      <Link to="/service">Service</Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4>Help</h4>
                  <ul>
                    <li className={Style.footerMenuItem}>
                      <Link to="/contact">Contact</Link>
                    </li>
                    {/*
                    <li className={Style.footerMenuItem}>
                      <Link to="help-center">Help Center</Link>
                    </li>
                    */}
                  </ul>
                </div>
              </div>
            </Col>
            <Col
              lg={{ span: 2, offset: 1 }}
              md={{ span: 3, offset: 3 }}
              className={Style.socialColFooter}
            >
              <span>
                <h4>Social Media</h4>
                <ul className={Style.footerIconsContainer}>
                  <li>
                    <a href="https://www.linkedin.com/in/elia-de-prisco-15572911/" target="_blank" rel="noreferrer">
                      <img
                        src={Linkedin}
                        className={Style.footerIcons}
                        alt="linkedin"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@EliaDePriscoOfficial" target="_blank" rel="noreferrer">
                      <img
                        src={Youtube}
                        className={Style.footerIcons}
                        alt="youtube"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/eliadepriscofficial/" target="_blank" rel="noreferrer">
                      <img
                        src={Instagram}
                        className={Style.footerIcons}
                        alt="instagram"
                      />
                    </a>
                  </li>
                </ul>
              </span>
            </Col>
          </Row>
          <div className={Style.bottomFooter}>
            <small>@2022 - {new Date().getFullYear()} Elia De Prisco - All Rights Reserved</small>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
