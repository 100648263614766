import React from "react";

// Components
import CustomRow from "../../../../CommonComponents/CustomRow/CustomRow";

// Images
import Image1 from "../../../../Assets/images/custom_row_hp_1.webp";
import Image2 from "../../../../Assets/images/custom_row_hp_2.webp";
import Image3 from "../../../../Assets/images/custom_row_hp_3.webp";

// Styles
import Style from "./WorkTogether.module.css";

const workTogether = [
  {
    image: Image1,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "Schedule an appointment",

    description:
      "All success stories start with a first step: all you have to do is fill in the form and schedule our first appointment.",
  },
  {
    image: Image2,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "Tell me more about you",
    description:
      "I will ask you a few questions about your business and your goals, so that I can understand your needs and offer you the best solution.",
    orderColumnOne: 2,
    orderColumnTwo: 1,
  },
  {
    image: Image3,
    textButtonOne: "Find out who I am",
    textButtonTwo: "Contact Now",
    pathButtonOne: "/about",
    pathButtonTwo: "/contact",
    title: "Let’s start a path together",
    description:
      "I will create a personalized plan for your business, so that you can achieve your goals and grow your business.",
  },
];

const WorkTogether = () => {
  return (
    <div className={Style.workTogetherContainer}>
      <div className={Style.sectionTitle}>
        <div className={Style.yellowLine}></div>
        <h2>What can we do together</h2>
        <div className={Style.yellowLine}></div>
      </div>

      {workTogether.map((item, index) => (
        <CustomRow
          key={index}
          image={item.image}
          textButtonOne={item.textButtonOne}
          textButtonTwo={item.textButtonTwo}
          pathButtonOne={item.pathButtonOne}
          pathButtonTwo={item.pathButtonTwo}
          title={item.title}
          description={item.description}
          orderColumnOne={item.orderColumnOne ? item.orderColumnOne : 1}
          orderColumnTwo={item.orderColumnTwo ? item.orderColumnTwo : 2}
        />
      ))}
    </div>
  );
};

export default WorkTogether;
