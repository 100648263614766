import React from "react";

// Images
import Lyopay from "../../Assets/icons/lyopay.svg";
import JcManagement from "../../Assets/icons/jcmanagement.svg";
import Munaretto from "../../Assets/icons/michieleMunaretto.svg";
import Paco from "../../Assets/icons/paco.svg";
import SecurityAgency from "../../Assets/icons/securityAgency.svg";
import LightHouse from "../../Assets/icons/lightHouse.svg";

// Styles
import Style from "./MyCustomers.module.css";

const customers = [
  {
    name: "Lyopay",
    image: Lyopay,
  },
  {
    name: "Jc Management",
    image: JcManagement,
  },
  {
    name: "Michiele Munaretto",
    image: Munaretto,
  },
  {
    name: "Paco",
    image: Paco,
  },
  {
    name: "Security Agency",
    image: SecurityAgency,
  },
  {
    name: "Light House",
    image: LightHouse,
  },
];

const MyCustomers = () => {
  return (
    <div className={Style.myCustomersContainer}>
      <div className={Style.sectionTitle}>
        <div className={Style.yellowLine}></div>
        <h2>My Customers</h2>
        <div className={Style.yellowLine}></div>
      </div>
      <div className={Style.logoGrid}>
        {customers.map((customer, index) => (
          <div key={index} className={Style.logoContainer}>
            <img src={customer.image} alt={customer.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyCustomers;
