import React from "react";

// Libraries
import { Row, Col } from "react-bootstrap";

// Icons
import Medal from "../../../../Assets/icons/medal.svg";
import Lens from "../../../../Assets/icons/lens.svg";
import Target from "../../../../Assets/icons/target.svg";
import Mic from "../../../../Assets/icons/mic.svg";

// Styles
import Style from "./MyBusiness.module.css";

const cards = [
  {
    icon: Medal,
    title: "32000",
    description: "Satisfied customers",
    alt: "medal",
  },
  {
    icon: Target,
    title: "15",
    description: "Projects Tackled",
    alt: "target",
  },
  {
    icon: Mic,
    title: "500+",
    description: "Speach Held",
    alt: "mic",
  },
  {
    icon: Lens,
    title: "30+",
    description: "Years of Experience",
    alt: "lens",
  },
];

const MyBusiness = () => {
  return (
    <Row>
      <Col lg={4} className={Style.titleContainer}>
        <div>
          <h2 className={Style.title}>My Business</h2>
        </div>
      </Col>
      <Col lg={8} className={Style.cardContainer}>
        {cards.map((card, index) => (
          <div key={index} className={Style.card}>
            <div className={Style.iconContainer}>
              <img alt={card.alt} src={card.icon} />
            </div>
            <div className={Style.content}>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default MyBusiness;
