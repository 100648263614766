import React from "react";

// Libraries
import { Row, Col } from "react-bootstrap";

// Icons
import Flower from "../../Assets/icons/flower.svg";
import Check from "../../Assets/icons/check.svg";
import Book from "../../Assets/icons/book.svg";
import Heart from "../../Assets/icons/heart.svg";

// Styles
import Style from "./AboutMe.module.css";

const badges = [
  {
    icon: Flower,
    alt: "flower-icon",
    title: "Know Yourself",
    description:
      "The Seven Wise Men said it: having awareness of oneself is the basis for knowing where one wants to go.",
  },
  {
    icon: Check,
    alt: "check-icon",
    title: "Develop good habits",
    description:
      "Healthy habits help to live better. Because happiness, we know, depends only on our actions.",
  },
  {
    icon: Book,
    alt: "book-icon",
    title: "Enjoy your work",
    description:
      "Enjoying and rewarding yourself for your work is never a fault: on the contrary, it is the key to success.",
  },
  {
    icon: Heart,
    alt: "heart-icon",
    title: "Create valuable relationships",
    description:
      "In the age of hyper-connectedness, knowing how to network is crucial for generating more and more value.",
  },
];

const AboutMe = () => {
  return (
    <Row className={Style.customRow}>
      <Col lg={5} md={12} className={Style.aboutMeBoxContainer}>
        <div className={Style.aboutMeBox}>
          <h3>About me</h3>
          <p>
            I am a consultant, I am a trainer, I am a leader, I am a friend. I
            owe this to my 30 years of experience, which have put me in front of
            ever-challenging situations in the field of communication, both
            personal and corporate, intervening in group dynamics and in
            modifying of behaviour.
          </p>
        </div>
      </Col>
      <Col lg={7} md={12}>
        <div className={Style.aboutMeBadgeContainer}>
          {badges.map((badge, index) => (
            <div key={index} className={Style.aboutMeBadge}>
              <div className={Style.badgeHeader}>
                <img alt={badge.alt} src={badge.icon} />
                <h3>{badge.title}</h3>
              </div>
              <p>{badge.description}</p>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default AboutMe;
