import React from 'react'

// Libreries
import { Col, Row } from 'react-bootstrap'
//import { Link } from 'react-router-dom'

// Icons
import Linkedin from "../../../../Assets/icons/linkedin.svg"
import Youtube from "../../../../Assets/icons/youtube.svg"
import Instagram from "../../../../Assets/icons/instagram.svg"
import Explosion from "../../../../Assets/icons/explosion.png"

// Styles
import Style from './Hero.module.css'

const Hero = () => {
    return (
        <Row>
            <Col lg={8} xl={8}>
                <div className={Style.leftBox}>
                    <div className={Style.social}>
                        <div className={Style.line}></div>
                        <ul className={Style.list}>
                            <li>
                                <a href="https://www.linkedin.com/in/elia-de-prisco-15572911/" target="_blank" rel="noreferrer">
                                    <img src={Linkedin} alt="linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/@EliaDePriscoOfficial" target="_blank" rel="noreferrer">
                                    <img src={Youtube} alt="youtube" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/eliadepriscofficial/" target="_blank" rel="noreferrer">
                                    <img src={Instagram} alt="instagram" />
                                </a>
                            </li>
                        </ul>
                        <div className={Style.line}></div>
                    </div>

                    <div className={Style.header}>
                        <div className={Style.title}>
                            <h1>
                                <span className={Style.evidence}>
                                    <span className={Style.apexOpened}></span>
                                    <span className={Style.text}>Sometimes</span>
                                </span> , you need<br /> to make a change to feel alive and in control of your{' '}
                                <div className={Style.closeText}>
                                    <span className={Style.apenClosed}></span>
                                    <span className={Style.text}>life.</span>
                                </div>
                                <div className={Style.subtitle}>
                                    Elia De Prisco <img src={Explosion} alt="explosion" />
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg={4} xl={4} className={Style.responsive}>
                <div className={Style.rightBox}>
                    <div className={Style.ellipseImage}></div>
                    <div className={Style.ellipseImageBorder}></div>
                </div>
            </Col>
        </Row>
    )
}

export default Hero