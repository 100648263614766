import React from "react";

// Styles
import Style from "./PathTogether.module.css";

// Icons
import TwoPersons from "../../../../Assets/icons/two_persons.svg";
import ThreePersons from "../../../../Assets/icons/three_persons.svg";
import Thinker from "../../../../Assets/icons/thinker.svg";

const cards = [
  {
    image: ThreePersons,
    title: "Business Management",
    description:
      "I help CEOs of large fintech and technology companies around the world to better manage their work and responsibilities.",
  },
  {
    image: TwoPersons,
    title: "Manager Training",
    description:
      "I provide training for tomorrow's managers, supporting them in their daily performance and giving them a vision.",
  },
  {
    image: Thinker,
    title: "Performance Coach",
    description:
      "I like to become the friendly guide, the person to rely on, of people and with them build their own success, personal and professional. ",
  },
];

const PathTogether = () => {
  return (
    <div className={Style.pathTogetherContainer}>
      <div className={Style.headerContainer}>
        <div className={Style.header}>
          <h2>Whoever you are, we can start</h2>
          <div className={Style.sectionTitle}>
            <div className={Style.yellowLine}></div>
            <h2>a path together</h2>
            <div className={Style.yellowLine}></div>
          </div>
        </div>
        <p>
          Thousands have realised their potential thanks to the work we have
          done together: my job is to help people understand and trust
          themselves.
        </p>
      </div>
      <div className={Style.content}>
        {cards.map((card, index) => (
          <div className={Style.card} key={index}>
            <div className={Style.iconContainer}>
              <img src={card.image} alt="card" />
            </div>

            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PathTogether;
