import React, { useState } from "react";

// Libraries
import { useLocation, Link } from "react-router-dom";

// Utils
import classNames from "../../Utils/classNames";

// Icons
import Linkedin from "../../Assets/icons/linkedin.svg";
import Youtube from "../../Assets/icons/youtube.svg";
import Instagram from "../../Assets/icons/instagram.svg";

// Images
import Logo from "../../Assets/logos/logo.png";
import LogoWhite from "../../Assets/logos/logo_white.svg";

// Styles
import Style from "./NavBar.module.css";

const navBarMenu = {
  items: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Me",
      path: "/about",
    },
    {
      name: "Service",
      path: "/service",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ],
  whiteRoutes: ["/service"],
};

const NavBar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  return (
    <header className={Style.navBar}>
      {/* Logo */}
      <Link to="/" className={Style.navBarLogo}>
        <img
          
          alt="logo"
          src={
            navBarMenu.whiteRoutes.includes(location.pathname) && !open
              ? LogoWhite
              : Logo
          }
        />
      </Link>

      {/* Menu */}
      <NavBarMenu navBarMenu={navBarMenu} location={location} />

      {/* Empty Span */}
      <div className={Style.navBarIcon}>
        <div className={Style.navBarIconDesktop}></div>
        <div
          className={classNames(Style.navBarIconMobile, open && Style.active)}
          onClick={() => {
            setOpen(!open);
            if (!open) {
              document.body.classList.add(Style.bodyScrollDisabled);
            } else {
              document.body.classList.remove(Style.bodyScrollDisabled);
            }
          }}
        >
          <span
            className={Style.iconPartOne}
            style={{
              backgroundColor:
                navBarMenu.whiteRoutes.includes(location.pathname) &&
                !open &&
                "white",
            }}
          ></span>
          <span
            className={Style.iconPartTwo}
            style={{
              backgroundColor:
                navBarMenu.whiteRoutes.includes(location.pathname) &&
                !open &&
                "white",
            }}
          ></span>
          <span
            className={Style.iconPartThree}
            style={{
              backgroundColor:
                navBarMenu.whiteRoutes.includes(location.pathname) &&
                !open &&
                "white",
            }}
          ></span>
        </div>
      </div>

      <MobileMenu
        navBarMenu={navBarMenu}
        location={location}
        open={open}
        setOpen={setOpen}
      />
    </header>
  );
};

export default NavBar;

const NavBarMenu = ({ navBarMenu, location }) => {
  return (
    <nav>
      <ul className={Style.navBarMenu}>
        {navBarMenu.items.map((item, index) => {
          return (
            <li
              key={index}
              className={classNames(
                location.pathname === item.path
                  ? Style.navBarMenuItemActive
                  : Style.navBarMenuItem
              )}
            >
              <Link
                className={classNames(
                  navBarMenu.whiteRoutes.includes(location.pathname)
                    ? Style.navBarMenuLinkWhite
                    : Style.navBarMenuLink,
                  location.pathname === item.path && Style.navBarMenuLinkActive
                )}
                to={item.path}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const MobileMenu = ({ navBarMenu, location, open, setOpen }) => {
  return (
    <div className={classNames(Style.mobileMenu, open && Style.mobileMenuOpen)}>
      <nav>
        <ul className={Style.navBarMenuMobile}>
          {navBarMenu.items.map((item, index) => {
            return (
              <li
                key={index}
                className={classNames(
                  location.pathname === item.path
                    ? Style.navBarMenuItemActive
                    : Style.navBarMenuItem
                )}
              >
                <Link
                  className={classNames(
                    Style.navBarMenuLink,
                    location.pathname === item.path &&
                      Style.navBarMenuLinkActive
                  )}
                  onClick={() => {
                    setOpen(false);
                    document.body.classList.remove(Style.bodyScrollDisabled);
                  }}
                  to={item.path}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={Style.menuIconsContainer}>
        <img className={Style.menuIcon} alt="linkedin" src={Linkedin} />
        <img className={Style.menuIcon} alt="youtube" src={Youtube} />
        <img className={Style.menuIcon} alt="instagram" src={Instagram} />
      </div>
    </div>
  );
};
