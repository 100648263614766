import React from 'react'

// Components
import { Col, Row } from 'react-bootstrap'

// Icons
import Icon1 from '../../../../Assets/icons/contact1.svg'
import Icon2 from '../../../../Assets/icons/contact2.svg'
import Icon3 from '../../../../Assets/icons/contact3.svg'
import Icon4 from '../../../../Assets/icons/contact4.svg'
import Icon5 from '../../../../Assets/icons/contact5.svg'
import Icon6 from '../../../../Assets/icons/contact6.svg'
import Shape1 from '../../../../Assets/icons/shape.png'

// Styles
import Style from './Hero.module.css'

const Hero = () => {
    return (
        <Row>
            <Col xl={12} className={Style.content}>
                <h1 className={Style.title}>
                    Let's <span className={Style.evidence}>contact me</span> and talk about your idea
                </h1>
                <img className={Style.firstIcon} src={Icon1} alt="icon" />
                <img className={Style.secondIcon} src={Icon2} alt="icon" />
                <img className={Style.thirdIcon} src={Icon3} alt="icon" />
                <img className={Style.fourthIcon} src={Icon4} alt="icon" />
                <img className={Style.fiveIcon} src={Icon5} alt="icon" />
                <img className={Style.sixIcon} src={Icon6} alt="icon" />
                <img className={Style.textShape} src={Shape1} alt="shape" />
            </Col>
        </Row>
    )
}

export default Hero