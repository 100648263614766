import React from "react";

// Components
import Citation from "./Components/Citation/Citation";
import CustomRows from "./Components/CustomRows/CustomRows";
import Hero from "./Components/Hero/Hero";
import MyCustomers from "../../CommonComponents/MyCustomers/MyCustomers";
import WhatCanTogether from "./Components/WhatCanTogether/WhatCanTogether";

// Styles
import Style from "./Service.module.css";

const Service = () => {
  return (
    <>
      <section className={Style.hero}>
        <Hero />
      </section>
      <section className={Style.whatCanTogether}>
        <WhatCanTogether />
      </section>
      <section className={Style.myCustomers}>
        <MyCustomers />
      </section>
      <section className={Style.citation}>
        <Citation />
      </section>
      <section className={Style.customRows}>
        <CustomRows />
      </section>
    </>
  );
};

export default Service;
