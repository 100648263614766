import React from "react";

// Libraries
import { Col, Row } from "react-bootstrap";

// Icons
import QuotationMarks from "../../../../Assets/icons/quotation_mark.svg";
import Explosion from "../../../../Assets/icons/explosion.png";

// Styles
import Style from "./Citation.module.css";

const Citation = () => {
  return (
    <div className={Style.statsContainer}>
      <Row>
        <Col lg={8}>
          <div className={Style.citation}>
            <h2>
              <img src={QuotationMarks} alt="quotation marks" />
              <span className={Style.yelloWord}>Sometimes</span>, you need to
              make a change to feel alive and in control of your{" "}
              <div className={Style.quotationMarks}>
                life. <img src={QuotationMarks} alt="quotation marks" />
              </div>
            </h2>
          </div>
        </Col>
        <Col lg={4}>
          <div className={Style.sign}>
            <h2>Elia De Prisco</h2>
            <img src={Explosion} alt="explosion" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Citation;
