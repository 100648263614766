import React from "react";

// Components
import AboutMe from "../../CommonComponents/AboutMe/AboutMe";
import MyCustomers from "../../CommonComponents/MyCustomers/MyCustomers";
import PathTogether from "./Components/PathTogether/PathTogether";
import Stats from "./Components/Stats/Stats";

// Components
import Hero from './Components/Hero/Hero'

// Styles
import Style from "./About.module.css";
import NoLimits from "./Components/NoLimits/NoLimits";

const About = () => {
  return (
    <>
      <section className={Style.hero}>
        <Hero />
      </section>
      <section className={Style.aboutMe}>
        <AboutMe />
      </section>
      <section className={Style.myCustomers}>
        <MyCustomers />
      </section>
      <section className={Style.noLimits}>
        <NoLimits />
      </section>
      <section className={Style.stats}>
        <Stats />
      </section>
      <section className={Style.pathTogether}>
        <PathTogether />
      </section>
    </>
  );
};

export default About;
