import React from "react";

// Components
import ContactForm from "./Components/ContactForm/ContactForm";
import Hero from "./Components/Hero/Hero";

// Styles
import Style from "./Contact.module.css";

const Contact = () => {
  return (
    <>
      <section className={Style.hero}>
        <Hero />
      </section>
      <section className={Style.contactForm}>
        <ContactForm />
      </section>
    </>
  );
};

export default Contact;
