import React from "react";

// Components
import NavBar from "../../CommonComponents/NavBar/NavBar";
import Footer from "../../CommonComponents/Footer/Footer";

// Libraries
import { Outlet } from "react-router-dom";

// Styles
import Style from "./MainLayout.module.css";

const MainLayout = () => {
  return (
    <>
      <div className={Style.container}>
        <NavBar />
        <main>
          <Outlet />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
